<template>
  <div class="charts-item">
    <!-- 饼状图 -->
    <div class="pieEcharts">
      <span class="p">本月累计</span>
      <echartsPie :option="option" :id="ids"></echartsPie>
    </div>
    <!-- 百分百 -->
    <!-- <div class="percent">
      <div class="singleP" v-for="(item, index) in pieData" :key="index">
        <div class="c" :style="{ background: item.bg }"></div>
        <div class="name">{{ item.name }}</div>
        <div class="per">{{ item.value }}</div>
      </div>
    </div> -->
  </div>
</template>
<script>
// import echartsPie from "@/components/echartsPie/echartsPie.vue";
import echartsPie from "@/components/charts/charts.vue";

export default {
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  components: {
    echartsPie,
  },
  watch: {
    "$attrs.chartsUUID"() {
      this.setOption();
    },
  },
  created() {
    this.ids = `${this.id}-pie`;
    this.setOption();
  },
  data() {
    return {
      ids: "",
      option: {},
    };
  },
  methods: {
    setOption() {
      let { xData, yData, legged } = this.$attrs;
      // if (dbDate&&dbDate.length) {
      //   list.push(`${dbDate[0]}-${dbDate[1]}`);
      // }
      yData.length > 1 ? legged.push("同比") : "";
      this.option = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          show: true,
          right: "0",
          top: "center",
          icon: "circle",
          orient: "vertical",
          itemHeight: 12,
          itemWidth: 12,
        },
        grid: {
          top: 45,
          left: 10,
          right: 40,
          bottom: 20,
        },
        color: [
          "rgb(203,155,255)",
          "rgb(149,162,255)",
          "rgb(58,186,255)",
          "rgb(119,168,249)",
          "rgb(235,161,159)",
        ],
        series: [
          {
            type: "pie",
            radius: ["50%", "70%"],
            center: ["50%", "55%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "14",
                color: "#666",
              },
            },
            labelLine: {
              show: false,
            },
            data: yData.map((item, index) => {
              return {
                name: legged[index],
                value: item.total,
              };
            }),
          },
        ],
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.charts-item {
  height: 100%;
  display: flex;
  justify-content: space-between;
  .pieEcharts {
    width: 100%;
    position: relative;
    .p {
      position: absolute;
      top: 46px;
      left: 8%;
      font-size: 12px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
    }
  }
  .percent {
    width: 47%;
    padding-top: 84px;
    .singleP {
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 25px;
      font-size: 11px;
      color: rgba(0, 0, 0, 0.65);
      .c {
        width: 6px;
        height: 6px;
        border-radius: 3px;
        margin-right: 6px;
      }
      .name {
        margin-right: 60px;
      }
    }
  }
}
</style>
