var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "charts-item" }, [
    _c(
      "div",
      { staticClass: "pieEcharts" },
      [
        _c("span", { staticClass: "p" }, [_vm._v("本月累计")]),
        _c("echartsPie", { attrs: { option: _vm.option, id: _vm.ids } })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }